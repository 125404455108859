import {Box, Center, Table, TableContainer, Tbody, Td, Text, Tr, VStack, Link} from '@chakra-ui/react';

import fiveStandLeagueContent from './five-stand-league.json';


export const FiveStandLeague = () => {
  return (
    <div>
        <iframe width="850"
                height="1100"
                src={fiveStandLeagueContent.content.main}
                title={'Five Stand League Flyer'} />
    </div>
  );
};
