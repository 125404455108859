import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Center,
  Stack,
  Collapse,
  Icon,
  Link,
  Show,
  Hide,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';

import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';

import { useUser } from '../../Context';

import { LoginModal } from '../';

export const WithSubnavigation = () => {
  const { user } = useUser();

  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: isLoginOpen, onClose: onLoginClose, onOpen: onLoginOpen } = useDisclosure();

  return (
    <Box position="absolute" w="100%" zIndex={1}  bg={{ base: "#333", md: "rgba(0,0,0,0.5)" }}>
      <Flex
        bg={"rgba(0,0,0,0.5)"}
        color={useColorModeValue('white', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor="#FD5521"
        align={'center'}>
        <Flex
          flex={{ base: 1, lg: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', lg: 'none' }}>
          <IconButton
            onClick={onToggle}
            _hover={{ bg: "white", color: 'gray.800' }}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', lg: 'start' }}>
          <Link href="/" _hover={{ textDecoration: 'none'}}>
            <Flex flexDirection='column' w="240px">
              <Center>
                <Text color="#FFF" fontSize="30px" fontWeight="700">Golden Gun Club</Text>
              </Center>
              <Center>
                <Text color="#FFF">Watkins, CO</Text>
              </Center>
            </Flex>
          </Link>
          <Flex display={{ base: 'none', lg: 'flex' }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, lg: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={6}>
          <Button
            as={'a'}
            color={'white'}
            _active={{
              color: '#FD5521',
            }}
            fontSize={'sm'}
            fontWeight={400}
            variant={'link'}
            onClick={onLoginOpen}>
              {user ? (
                <>
                  <Hide above="xl">Logout</Hide>
                  <Show above="xl">Logged in as {user.username}</Show>
                </>
              ) : (
                'Login'
              )}
          </Button>
          {/* <Button
            display={{ base: 'none', md: 'inline-flex' }}
            fontSize={'sm'}
            fontWeight={600}
            color={'white'}
            bg={'pink.400'}
            href={'#'}
            _hover={{
              bg: 'pink.300',
            }}>
            Sign Up
          </Button> */}
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>

      <LoginModal isOpen={isLoginOpen} onClose={onLoginClose} />
    </Box>
  );
}

const DesktopNav = () => {
  const { user } = useUser();
  const linkColor = useColorModeValue('white', 'gray.200');
  const linkHoverColor = useColorModeValue('#FD5521', 'white');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  const groups = user?.signInUserSession?.accessToken?.payload?.['cognito:groups'];
  const isAdmin = groups?.includes('admins') || groups?.includes('match_directors');

  return (
    <Stack direction={'row'} spacing={4} alignItems={'center'}>
      {[...NAV_ITEMS, ...isAdmin ? [{ label: 'Admin', href: '/admin' }] : []].map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link
                p={2}
                href={navItem.href ? navItem.href : navItem?.children ? navItem.children[0].href : '#'}
                fontSize={'sm'}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}>
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}>
                <Stack>
                  {navItem.children.map((child, index) => (
                    index !== 0 && (
                      <DesktopSubNav key={child.label} {...child} />
                    )
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Link
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      color={'gray.900'}
      _hover={{ bg: useColorModeValue('#FD5521', 'gray.900') }}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            _groupHover={{ color: 'white' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'} _groupHover={{ color: 'white' }}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'white'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  const { user } = useUser();
  const isAdmin = user?.signInUserSession?.accessToken?.payload?.['cognito:groups']?.includes('admins');

  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ lg: 'none' }}>
      {[...NAV_ITEMS, ...isAdmin ? [{ label: 'Admin', href: '/admin' }] : []].map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? '#'}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}>
        <Text
          fontWeight={600}
          color={useColorModeValue('gray.600', 'gray.200')}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: 'Facilities',
    children: [
      {
        label: 'Facilities',
        subLabel: '',
        href: '/facilities',
      },
      {
        label: 'Shotgun',
        subLabel: 'Trap, Skeet, and Sporting Clays',
        href: '/facilities/shotgun',
      },
      {
        label: 'Rifle & Pistol',
        subLabel: 'Recreational & Competitive Outdoor Shooting',
        href: '/facilities/rifle-pistol',
      },
    ],
  },
  {
    label: 'Visitors',
    href: '/visitors',
  },
  {
    label: 'Membership',
    children: [
      {
        label: 'Membership',
        subLabel: '',
        href: '/membership',
      },
      {
        label: 'Range Rules',
        subLabel: '',
        href: '/membership/range-rules',
      },
    ],
  },
  {
    label: 'Matches & Competition',
    children: [
      {
        label: 'Matches & Competition',
        subLabel: '',
        href: '/matches-competition',
      },
      {
        label: 'Cowboy Silhouette',
        subLabel: 'Western Themed Steel Target Matches',
        href: '/matches-competition/cowboy-silhouette',
      },
      {
        label: 'CMP Highpower/Service Rifle',
        subLabel: 'Honoring Our Nation\'s Traditions',
        href: '/matches-competition/cmp-highpower-service-rifle',
      },
      {
        label: 'Bowling Pin Match',
        subLabel: 'Bowling With Handguns!',
        href: '/matches-competition/bowling-pin-match',
      },
      {
        label: 'Black Powder Silhouette',
        subLabel: 'Long Range Accuracy with Traditional Firearms',
        href: '/matches-competition/black-powder-silhouette',
      },
      {
        label: 'NRA Action Pistol',
        subLabel: 'Handgun Speed & Accuracy',
        href: '/matches-competition/nra-action-pistol',
      },
      {
        label: 'Trap League',
        subLabel: '',
        href: '/matches-competition/trap-league',
      },
      {
        label: 'Skeet & 5-Stand League',
        subLabel: '',
        href: '/matches-competition/five-stand-league',
      },
    ],
  },
  {
    label: 'About Us',
    children: [
      {
        label: 'About Us',
        subLabel: '',
        href: '/about-us',
      },
      {
        label: 'Calendar',
        subLabel: '',
        href: '/about-us/calendar',
      },
      {
        label: 'Board Minutes & Notes',
        subLabel: '',
        href: '/about-us/board-minutes-notes',
      },
      {
        label: 'Range Camera',
        subLabel: '',
        href: '/about-us/range-camera',
      },
      {
        label: 'FAQ',
        subLabel: '',
        href: '/about-us/faq',
      },
    ],
  },
];
