import {Box, Center, Table, List, Heading, TableContainer, Tbody, Td, Text, Tr, VStack, Link} from '@chakra-ui/react';

import trapLeagueContent from './trap-league.json';


export const TrapLeague = () => {
  return (
      <iframe width="850"
              height="1100"
              src={trapLeagueContent.content.main}
              title={'Trap League Flyer'} />
  );
};
